
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import TheForm from '@/pages/invite/components/TheForm.vue'
import TheLand from '@/pages/invite/components/TheLand.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'LinkPage',
  components: { TheLand, Scroller, Line, TheForm, Pic },
  setup () {
    const registered = shallowRef(false)

    return {
      registered,
    }
  },
})
